import { Result, Spin } from '@/UI';
import React, { useState } from 'react';

interface Props {
  renderContent: React.ReactNode;
}

const Author: React.FC<Props> = ({ renderContent }) => {
  const [isAllowed, setIsAllowed] = useState<boolean>(true);

  if (typeof isAllowed === 'undefined')
    return (
      <div className="flex h-screen items-center justify-center">
        <Spin />
      </div>
    );

  return (
    <>
      {!isAllowed ? (
        <div className="flex-1 flex items-center justify-center h-screen bg-th-background">
          <Result status="403" title="403" subTitle="Xin lỗi, bạn không có quyền truy cập trang web này." />
        </div>
      ) : (
        renderContent
      )}
    </>
  );
};

export default Author;
