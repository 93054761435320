import { Dropdown, MenuProps } from '@/UI';
import { LogoutIcon, Moon, Setting, Sun } from '@/components/icons';
import { useAuth } from '@/configs/Auth/client';
import cn from 'classnames';
import { useTheme } from '@/provider/themeProvider';

const SwitchTheme = ({ checked, onChange }: { checked: boolean; onChange: () => void }) => {
  const Icon = checked ? Moon : Sun;
  const { ref } = useTheme();

  return (
    <button
      ref={ref}
      className="relative hover:border-th-gray-300 rounded-[12px] w-[38px] h-[21px] border border-solid border-th-gray-100 bg-th-background-overlay transition-all cursor-pointer"
      onClick={onChange}
      id="switch-theme"
    >
      <span
        className={cn(
          'absolute top-[2px] left-[1px] w-4 h-4 rounded-full bg-th-background flex items-center justify-center transition-all',
          {
            'translate-x-[18px]': checked,
          }
        )}
      >
        <Icon className="text-th-text" />
      </span>
    </button>
  );
};

export default function UserBtn() {
  const { userInfo, handleLogOut } = useAuth();
  const { theme, toggleTheme } = useTheme();

  const items: MenuProps['items'] = [
    {
      key: 'logout',
      label: 'Đăng xuất',
      icon: <LogoutIcon className="text-[1rem] inline-flex" />,
    },
    {
      type: 'divider',
    },
    {
      key: 'theme',
      label: (
        <div className="flex items-center gap-2" onClick={(e) => e?.stopPropagation()}>
          <label htmlFor="switch-theme">Chế độ sáng</label>
          <SwitchTheme
            checked={theme === 'dark'}
            onChange={() => toggleTheme(theme === 'dark' ? 'light' : 'dark')}
          />
        </div>
      ),
      icon: <Moon />,
    },
  ];

  return (
    <div className="flex items-center gap-4 text-text-secondary flex-shrink-0 overflow-hidden">
      <div className="items-center flex gap-2 leading-4">
        <Setting /> Thiết lập
      </div>
      <Dropdown
        arrow={{ pointAtCenter: true }}
        trigger={['click']}
        placement="bottomRight"
        menu={{
          items,
          className: 'custom-menu-dropdown',
          onClick: (menuItem) => {
            if (menuItem.key === 'logout') {
              handleLogOut();
            }
          },
        }}
      >
        <div className="bg-th-gray-200 min-w-10 w-10 h-10 flex items-center justify-center text-white text-lg font-extrabold rounded-full cursor-pointer leading-6">
          {userInfo?.username?.charAt(0)?.toUpperCase()}
        </div>
        {/* <Avatar src={userInfo?.avatar} alt="avt" size="large" className="min-w-[40px] cursor-pointer" />*/}
      </Dropdown>
    </div>
  );
}
