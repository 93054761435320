export const PAGE_URL = ['/marketing', '/kai', '/insight-deployment', 'end-user-insight', '/pmp'];

export const NAV_ITEMS = [
  {
    label: 'Khách Hàng',
    key: '/customer',
  },
  {
    label: 'Giải Pháp Cho Khách Hàng',
    key: '/customer-solution',
    children: [
      {
        label: 'Customer Insight Solution',
        key: '/insight',
      },
      {
        label: 'KAI Solution',
        key: '/kai',
      },
      {
        label: 'Marketing Solution',
        key: '/marketing',
      },
    ],
  },
  {
    label: 'Product Solution',
    key: '/product-solution',
  },
  {
    label: 'Giải Pháp Cho End-user ',
    key: '/end-user-insight',
  },
  {
    label: 'PMP',
    key: '/pmp',
  },
];

export const getRouteLabel = (navKey, subNavKey) => {
  const navItem = NAV_ITEMS.find((item) => item.key === navKey);
  const subNavItem = navItem?.children?.find((item) => item.key === subNavKey);
  if (!navItem) return 'GAM Solutions';

  const subnav = subNavItem?.label ? `${subNavItem?.label} - ` : '';
  return `${subnav} ${navItem?.label} - GAM Solutions`;
};
